export class API {
  static readonly AUTH_LOGIN = 'auth/business-login';
  static readonly SA_LOGIN = 'auth/login';
  static readonly AUTH_REFRESH_TOKEN = 'auth/token';
  static readonly BUSINESS = 'business/account';
  static readonly SET_REFERRAL_PROGRAM =
    'business/account/settings/referral-program';
  static readonly CUSTOMERS = 'business/customers';
  static readonly CUSTOMERS_ACTIVITIES = 'business/activities';
  static readonly SET_PASSWORD = 'users/profile/set-password';
  static readonly USER_PROFILE = 'users/profile';
  static readonly CUSTOMERS_BY_ID = 'business/customers/id';
  static readonly CUSTOMERS_BY_PHONE = 'business/customers/code';
  static readonly CUSTOMERS_SEARCH = 'business/customers/search';
  static readonly CUSTOMER_ADD_BY_PHONE = 'business/customers/add';
  static readonly CUSTOMER_SET_POINTS = 'business/customers/add-points';
  static readonly CUSTOMER_REDEEM = 'business/customers/redeem';
  static readonly REWARDS = 'business/rewards';
  static readonly USERS = 'business/users';
  static readonly USERS_DETAIL = 'business/users';
  static readonly USERS_ACTIVITIES = 'business/users';
  static readonly USERS_CREATE = 'business/users';
  static readonly USERS_UPDATE = 'business/users';
  static readonly ANNOUNCEMENTS = 'business/announcements';
  static readonly ACTIVITIES = 'business/activities';
  static readonly ACTIVITY_VOID = 'business/void';
  static readonly QR_CODES_STATISTICS = 'business/qr/statistics';
  static readonly QR_LIST_STATISTICS = 'business/qr/list-statistics';
  static readonly QR_CODES = 'qr';
  static readonly SETTINGS = 'business/account/settings';
  static readonly SETTINGS_BY_METAID = 'business/account/settings/key';
  static readonly UPLOAD_IMAGE = 'media/upload';
  static readonly UPLOAD_IMAGE_SA = 'media/user-upload';
  static readonly PHONE_CHECK = 'auth/business-check';
  static readonly BUSINESS_SIGNUP = 'auth/business-signup';
  static readonly BUSINESS_VERIFY = 'auth/business-signup/verify';
  static readonly BUSINESS_RESEND = 'auth/business-signup/resend';
  static readonly BUSINESS_SET_PASS = 'auth/business-set-userpass';
  static readonly BUSINESS_SET_PASS_ISVALID =
    'auth/business-set-userpass/is-valid-code';
  static readonly BUSINESS_SET_PASS_RESEND =
    'auth/business-set-userpass/resend';
  static readonly BUSINESS_SET_PASS_VERIFY =
    'auth/business-set-userpass/verify';
  static readonly BUSINESS_FORGOT_PASSWORD = 'auth/forgot-password';
  static readonly BUSINESS_FORGOT_PASSWORD_ISINVALID =
    'auth/forgot-password/is-valid-code';
  static readonly BUSINESS_FORGOT_PASSWORD_RESEND =
    'auth/forgot-password/resend';
  static readonly BUSINESS_FORGOT_PASSWORD_VERIFY =
    'auth/forgot-password/verify';

  static readonly BUSINESS_PRINTER = 'business/printers';
  static readonly BUSINESS_SA = 'sa/business';
  static readonly BUSINESS_SA_SIGNUP = 'sa/business/signup';
  static readonly DASHBOARD_SCANNED_CODES = 'business/dashboard/scanned-code';
  static readonly DASHBOARD_REWARDS_REDEEMED =
    'business/dashboard/rewards-redeemed';
  static readonly DASHBOARD_GIVEN_POINTS = 'business/dashboard/given-points';
  static readonly DASHBOARD_NO_CUSTOMERS = 'business/dashboard/no-customers';
  static readonly CAMPAIGNS = 'business/campaigns';
  static readonly CAMPAIGNS_TAGS = 'business/campaigns/campaign-tags';
  static readonly CONDITIONS_TYPE = 'business/campaigns/conditions-type';
  static readonly CONDITIONS_ADD = 'business/campaigns/conditions/customer';
  static readonly CONDITIONS_DETAIL = 'business/campaigns/conditions/customer';
  static readonly CREATE_CAMPAIGNS = 'business/campaigns/text-message';
  static readonly UPDATE_CAMPAIGNS = 'business/campaigns';
  static readonly ESTIMATE_CAMPAIGNS = 'business/campaigns';
  static readonly ESTIMATE_CAMPAIGNS_CONDITIONS = 'business/campaigns';
  static readonly BUSINESS_PRICING = 'business/payments/pricing';
  static readonly PRICING_INFO = 'business/bid';
  static readonly BUSINESS_PRICING_INFO = 'business/account/plan-pricing';
  static readonly BUSINESS_ADD_ONS = 'business/payments/addons';
  static readonly BUSINESS_ADD_ONS_BUY = 'business/payments/addons/buy';
  static readonly BUSINESS_CHANGE_PLAN = 'business/payments/change-plan';
  static readonly BUSINESS_UPGRADE_PLAN = 'business/payments/plan/upgrade';
  static readonly DASHBOAR_BUSINESS_STATS = 'business/dashboard/stats';
  static readonly DASHBOAR_BUSINESS_STATS_REFERRAL =
    'business/dashboard/stats/referrals';
  static readonly DASHBOAR_TOP_REWARDS =
    'business/dashboard/top-rewards-redeemed';
  static readonly DASHBOAR_TOP_CUSTOMERS_POINTS =
    'business/dashboard/top-customers-points';
  static readonly DASHBOAR_CHART = 'business/dashboard/stats/charts';
  static readonly SEND_TEXT_CAMPAIGN = 'business/campaigns';
  static readonly PAYMENT_METHODS = 'business/payments/payment-methods';
  static readonly ADD_PAYMENT_METHODS = 'business/payments/payment-methods/card';
  static readonly INVOICES = 'business/payments/invoices';
  static readonly PENDING_APPROVE = 'business/activities/pending-approvals';
  static readonly REFUSE = 'business/activities/refuse-transactions';
  static readonly APPROVE = 'business/activities/approve-transactions';
  static readonly SET_POINTS = 'business/customers/add-points';
  static readonly OFFERS = 'business/offers';
  static readonly OFFERS_TAG = 'business/tags';
  static readonly CAMPAIGN_INIT_OFFERS = 'business/campaigns/offer';
  static readonly OFFERS_EXSITING = 'business/offers/campaigns';
  static readonly CREATE_OFFER_EXSITING = 'business/campaigns';
  static readonly AUDIT = 'audit/ui';
  static readonly CHECK_BUSINESS_CODE_EXSITING = 'business/offers/is-code-exist';
  static readonly GLOBAL_TAGS = 'business/tags';
  static readonly BUSINESS_SUPPORT = 'auth/support-businesses';
  static readonly DASHBOARD_BUSINESS_NEW_RETURN = 'business/dashboard/stats/new-returning-customers';
  static readonly SERVICE_TOKENS = 'business/service-tokens';
  static readonly SERVICE_TOKENS_SCOPES = 'business/service-tokens/config/scopes';
  static readonly EMBEDDED_SIGN_UP = 'business/service-tokens/config/scopes';
  static readonly CUSTOMER_PAYMENT = 'business/account/settings';
  static readonly PAYMENT_GATEWAYS = 'business/payment-gateways';
  static readonly PAYMENT_GATEWAYS_PROVIDERS = 'business/payment-gateways/providers';
  static readonly PAYMENT_GATEWAYS_UPDATE = 'business/account/settings';
  static readonly PAYMENT_AUTHORIZATION = 'business/payment-gateways/providers/authorization';
  static readonly EMBEDDED_FORM = 'business/embedded-form'
  static readonly BUSINESS_ROLES = 'business/permissions/roles'
  static readonly BUSINESS_PERMISSIONS = 'business/permissions'
  static readonly CUSTOMER_TAGS = 'business/tags'
  static readonly PERMISSIONS_ROLES = 'business/permissions/roles'
  static readonly OFFERS_CALENDAR = 'business/offers/calendar';
  static readonly EMBEDDED_HMC = 'business/hmc-embedded-form'
  static readonly UPLOAD_PROCESS_RAW_DATA = 'business/call-button/hmc/process-raw-data';


}
