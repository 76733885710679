
export const AISuggestionsType: any[] = [
    "ENCOURAGE_REPEAT_BUSINESS",
    "INCREASE_CUSTOMER_ENGAGEMENT",
    "BOOST_SALES_DURING_SLOW_PERIODS",
    "ATTRACT_NEW_CUSTOMERS",
    "INTRODUCE_NEW_PRODUCTS_OR_MENU_ITEMS",
    "ENCOURAGE_LARGER_PURCHASES",
    "REWARD_LOYAL_CUSTOMERS",
    "PROMOTE_SPECIAL_OCCASIONS",
    "GENERATE_WORD_OF_MOUTH",
    "CLEAR_STOCK_OR_MANAGE_INVENTORY",
];
