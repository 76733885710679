import { NgModule } from '@angular/core';
import { PhoneNumberPipe } from './phone-number.pipe';
import { StickyQRCurrencyPipe } from './stickyqr-currency.pipe';
import { DecimalPipe } from '@angular/common';
import { CountUpDirective } from './count-up.directive';
import { NumberDirective } from './numbers-only.directive';
import { MultipleDatepickerDirective } from './datepicker.directive';
import { StickySidebarDirective } from './sticky-sidebar.directive';
import { MaxValueDirective } from './max-value.directive';
import { MinValueDirective } from './min-value.directive';
import { ActiveDayDirective } from './active-day.directive';
import { NumberFormatPipe } from './number-format.pipe';
import { FormatDateTimezonePipe } from './format-date-timezone.pipe';
import { FormatDateTimePipe } from './timezone-date.pipe';
const PIPES = [
  PhoneNumberPipe, 
  StickyQRCurrencyPipe, 
  CountUpDirective, 
  NumberDirective, 
  MultipleDatepickerDirective, 
  StickySidebarDirective, 
  MaxValueDirective,
  MinValueDirective,
  ActiveDayDirective,
  NumberFormatPipe,
  FormatDateTimezonePipe,
  FormatDateTimePipe,
];

@NgModule({
  declarations: [...PIPES],
  imports: [],
  providers: [DecimalPipe],
  exports: [...PIPES],
})
export class PipesModule {}
