

import { Pipe, PipeTransform } from '@angular/core';
import { AppRepository } from '@sticky/state/app';
import { format, utcToZonedTime, toDate } from 'date-fns-tz';
import { enUS, vi } from 'date-fns/locale';
// import { vi as viLocale, enUS } from 'date-fns/locale';
@Pipe({
  name: 'dateTimezone'
})
export class FormatDateTimePipe implements PipeTransform {
  constructor(private appRepo: AppRepository) {}
  get business() {
    return this.appRepo?.business;
  }
  transform(
    value: string | Date,
    locale: string = 'vi'
  ): string {
    if (!value) return '';
    const timezone = this.business?.timeZone ?? 'America/Los_Angeles';
    try {
      // Danh sách các timezone có DST (có thể bổ sung thêm nếu cần)
      const dstTimezones = ['America/Los_Angeles', 'America/New_York', 'Europe/London'];

      // Nếu timezone có DST thì sử dụng PST (Etc/GMT+8) để tránh DST
      const displayTimezone = dstTimezones.includes(timezone) ? 'Etc/GMT+8' : timezone;

      // Chuyển đổi thời gian sang timezone đã chọn
      const dateInTargetTimezone = utcToZonedTime(toDate(value), displayTimezone);

      // Lấy locale để hiển thị đúng ngôn ngữ
      const selectedLocale = locale === 'en' ? enUS : vi;

      // Định dạng thời gian
      if (locale === 'vi') {
        return format(dateInTargetTimezone, 'dd/MM/yyyy HH:mm', { locale: selectedLocale });
      } else {
        return format(dateInTargetTimezone, 'MM/dd/yyyy hh:mm a', { locale: selectedLocale });
      }
      
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }
}
